import { FC } from "react";
import { AlittihadFeatures } from "data/types";
import Link from "components/Link";
import Badge from "components/Badge/Badge";
import Avatar from "components/Avatar/Avatar";

export interface Card5Props {
  className?: string;
  post: AlittihadFeatures;
}

const Card5: FC<Card5Props> = ({ className = "", post }) => {
  const { stitle, slug, slugimage, name, post_type, id, ctitle , curl ,updated_at , href, uimage } = post;

  let date = new Date(updated_at);
  var months = ["كانون ثاني/يناير", "شباط/فبراير", "آذار/مارس", "نيسان/إبريل", "أيار/مايو", "حزيران/يونيو",
    "تموز/يوليو", "آب/أغسطس", "أيلول/سبتمبر", "تشرين أول/أكتوبر", "تشرين ثاني/نوفمبر", "كانون أول/ديسمبر"
  ];
  var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
  var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()];
  
  return (
    <div
      className={`nc-Card5 relative p-5 group border border-neutral-200 hover:shadow-lg transition-shadow dark:border-neutral-700 rounded-3xl bg-white dark:bg-neutral-900 ${className}`}
    >
      <Link href={"/"+curl+"/"+slug} className="absolute inset-0 rounded-lg"></Link>

      <div className="flex flex-col">
      <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      
        <Badge
          key={id}
          name={ctitle}
          href={"/"+curl}
        />
    
    </div>
        <h2
          className="block text-base font-semibold text-neutral-800 dark:text-neutral-300 my-4"
          title={stitle}
        >
          <Link href={"/"+curl+"/"+slug} className="line-clamp-2" title={stitle}>
            {stitle}
          </Link>
        </h2>
        <Link
      href={'writer/'+href}
      className={`nc-CardAuthor2 relative inline-flex items-center ${className}`}
    >
      <Avatar
        sizeClass="h-10 w-10 text-base"
        containerClassName="flex-shrink-0 mr-3"
        radius="rounded-full"
        imgUrl={'https://alittihad44.com/images/users/'+uimage}
        userName={name}
      />
      <div>
        <h2
          className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
        >
          {name}
        </h2>
        <span
          className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}
        >
          <span>{delDateString}</span>
         
        </span>
      </div>
    </Link>
      </div>
    </div>
  );
};

export default Card5;
