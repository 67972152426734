import { FC } from "react";
import Heading from "components/Heading/Heading";
import { AlittihadFeatures } from "data/types";
import Card11 from "components/Card11/Card11";
import axios from 'axios'
import { useState, useEffect } from "react";


export interface SingleRelatedPostsProps {
  relatedPosts?: AlittihadFeatures[];
  moreFromAuthorPosts?: AlittihadFeatures[];
}



const SingleRelatedPosts: FC<SingleRelatedPostsProps> = ({
}) => {

  const [posts, setPost] = useState<AlittihadFeatures[]>([]);

  useEffect(() => {
   
    fetchData();
  }, []);


    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v2/latestnews"
      );
      setPost(response.data);



    };
 

  return (
    <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-28">
      {/* RELATED  */}
      <div className="container">
        <div>
          <Heading
            className="mb-10 text-neutral-900 dark:text-neutral-50"
            desc=""
          >
           قد يهمّكم أيضا.. 
          </Heading>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {posts.filter(  (_, i) => i >= 0 && i < 8).map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRelatedPosts;
