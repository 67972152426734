import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
import { AlittihadFeatures } from "data/types";
import Link from "components/Link";

export interface PostCardMetaV2Props {
  meta: Pick<AlittihadFeatures, "updated_at" | "name" | "stitle" | "slug" | "href" | "uimage" | "curl" | "slug">;
  hiddenAvatar?: boolean;
  className?: string;
  titleClassName?: string;
  avatarSize?: string;
}

const PostCardMetaV2: FC<PostCardMetaV2Props> = ({
  meta,
  hiddenAvatar = false,
  className = "leading-none text-xs",
  titleClassName = "text-base",
  avatarSize = "h-9 w-9 text-base",
}) => {
  const { updated_at, name, stitle, href, uimage, curl, slug } = meta;
  let date = new Date(updated_at);
  var months = ["كانون ثاني/يناير", "شباط/فبراير", "آذار/مارس", "نيسان/إبريل", "أيار/مايو", "حزيران/يونيو",
    "تموز/يوليو", "آب/أغسطس", "أيلول/سبتمبر", "تشرين أول/أكتوبر", "تشرين ثاني/نوفمبر", "كانون أول/ديسمبر"
  ];
  var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
  var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()];
  
  

  return (
    <div
      className={`nc-PostCardMetaV2 inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <div className="relative flex items-center space-x-2">
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={'https://alittihad44.com/images/users/'+uimage}
            userName={name}
          />
        )}
        <div>
          <h2 className={`block font-semibold ${titleClassName}`}>
            <Link href={curl +"/"+ slug} className="line-clamp-1">
              {stitle}
            </Link>
          </h2>

          <Link href={"writer/"+ href} className="flex mt-1.5">
            <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
              {name}
            </span>
            <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
              ·
            </span>
            <span className="text-neutral-500 dark:text-neutral-400 font-normal">
              {delDateString}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostCardMetaV2;
