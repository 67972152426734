import { FC } from "react";
import { AlittihadFeatures } from "data/types";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image";
import Badge from "components/Badge/Badge";
import Avatar from "components/Avatar/Avatar";

export interface Card2Props {
  className?: string;
  post: AlittihadFeatures;
  hiddenAvatar?: boolean;
  avatarSize?: string;


  size?: "normal" | "large";
}

const Card2: FC<Card2Props> = ({
  className = "h-full",
  size = "normal",
  hiddenAvatar = false,
  avatarSize = "h-7 w-7 text-sm",

  post,
}) => {
  const {id, stitle, slug, ctitle, curl , slugimage, updated_at, post_type, href, uimage, name } =
    post;
    let date = new Date(updated_at);
var months = ["كانون ثاني/يناير", "شباط/فبراير", "آذار/مارس", "نيسان/إبريل", "أيار/مايو", "حزيران/يونيو",
  "تموز/يوليو", "آب/أغسطس", "أيلول/سبتمبر", "تشرين أول/أكتوبر", "تشرين ثاني/نوفمبر", "كانون أول/ديسمبر"
];
var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()];



  return (
    <div className={`nc-Card2 group relative flex flex-col ${className}`}>
      <div className="block flex-shrink-0 flex-grow relative w-full h-0 pt-[75%] sm:pt-[55%] z-0">
        <Image
          fill
          sizes="(max-width: 600px) 480px, 800px"
          className="object-cover rounded-3xl"
          src={'https://alittihad44.com/' +slugimage}
          alt={stitle}
        />
        <PostTypeFeaturedIcon
          className="absolute bottom-2 left-2"
          postType={post_type}
          wrapSize="w-8 h-8"
          iconSize="w-4 h-4"
        />
             </div>

      <Link href={curl + '/' + slug} className="absolute inset-0" />

      <div className="mt-5 px-4 flex flex-col">
        <div className="space-y-3">


   <div className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}>
         <Badge
          key={id}
          name={ctitle}
          href={curl}
          color={'red'}
        />
         </div>
          <h2
            className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ${
              size === "large" ? "text-base sm:text-lg md:text-xl" : "text-base"
            }`}
          >
            <Link href={curl + '/' + slug} className="line-clamp-2" title={stitle}>
              {stitle}
            </Link>
          </h2>
                   {/*  avatar  */}

    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 leading-none text-xs`}
    >
      <Link href={'writer/'+ href} className="relative flex items-center space-x-2">
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={'https://alittihad44.com/images/users/'+uimage}
            userName={name}
          />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {name}
        </span>
      </Link>
      <>

        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {delDateString}
        </span>
      </>
    </div>
        {/*  end avatar  */}
          <span className="block text-neutral-500 dark:text-neutral-400 text-[15px] leading-6 ">
          { <div dangerouslySetInnerHTML={{ __html: post.article.substring(0, 250) }} /> }

          </span>
        </div>
        <div className="my-5 border-t border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex items-center justify-between">

        </div>
      </div>
    </div>
  );
};

export default Card2;
