import React from "react";
import SectionHero from "components/SectionHero/SectionHero";
import rightImg from "images/about-hero-right.png";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Image from "components/Image";
import SocialsList from "components/SocialsList/SocialsList";

const info = [
  {
    title: "🗺 بريد إلكتروني",
    desc: "editor@alittihad44.com",
  },
  {
    title: "العنوان",
    desc: "حيفا شارع الحريري 1",
  },
  {
    title: "☎ هاتف",
    desc: "048605400",
  },
];

const PageAbout = () => {

  const btnText = "اتصلوا بنا.."
  return (
    <div className={`nc-PageAbout relative`}>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div className={`nc-SectionHero relative`}>
      <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 text-center py-4">
           من نحن
          </h2>
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
      
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          تعد صحيفة الاتحاد من أهم الصحف العربية في البلاد، وذلك لكونها الصحيفة العربية المخضرمة والوحيدة التي بدأت بالصدور منذ قبل عام 1948، وكانت الصحيفة العربية الوحيدة الغير حكومية خلال فترة الحكم العسكري والتي استمرت حتى يومنا هذا.
          </span>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          ظهرت جريدة الاتحاد بداية كلسان حال عصبة التحرّر الوطني وعلى رأسها إميل توما وتوفيق طوبي وفؤاد نصّار وإميل حبيبي، صدر عددها الأول بتاريخ 14 أيار عام 1944 كصحيفة أسبوعية جامعة. وصدرت لاحقًا بعد تشرين الأول 1948 كلسان حال الحزب الشيوعي الإسرائيلي. تأسّست على يد إميل توما الذي كان صاحب الامتياز ومحررّها المسؤول في آن. أصدرت الجريدة خلال الفترة الانتدابية على فلسطين ملحقًا باللغة الإنجليزية إلى جانب ملحقًا آخر باللغة الأرمنية لكون الكثير من أبناء الطائفة الأرمنية مالوا إلى الانضمام للفكر والحركة الشيوعية التي تبنّته الجريدة كخطها التحريري. 
          </span>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
          في عهد الانتداب، لم تكن الجريدة ذائعة الانتشار بسبب طابعها المختلف عن باقي الصحافة العربية الفلسطينية، فقد امتنعت عن نشر المنوعات الإخبارية كالأخبار المثيرة والحفلات والأمسيات الغنائية والموسيقية. ولم يتم بذل الجهد من أجل جذب وإثارة القّراء، وركزت على نشر التعليقات والتحليلات السياسية واتخاذ خطة هادفة لنشر الدعاية الشيوعية التي رمت إلى مكافحة الاستعمار ودعوة جمهور العمال إلى التكاتف والعمل المشترك على حماية حقوقهم.
         </span>
         <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
         صدرت منذ أيار 1984 كصحيفة نصف أسبوعية حين كان لا زال إميل حبيبي محرّرها الأبرز. 
         </span>

          {!!btnText && <ButtonPrimary href="/contact">{btnText}</ButtonPrimary>}
        </div>
        <div className="flex-grow">
          <Image className="w-full" src={'https://www.alittihad44.com/images/news/2020.06.14.18.06.47-2019.11.26.01.14.16-5c3b098e68e82.png'} alt="" />

        </div>
      </div>
    </div>

     <SectionFounder />

    


      </div>
    </div>
  );
};

export default PageAbout;
