import React from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import { useState } from "react";
import axios from "axios";
import { Alert } from "components/Alert/Alert";



export interface SocialType {
  id: string;
  name: string;
  icon: string;
  slug: string;
}


export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const info = [
  {
    title: "عنواننا",
    desc: "حيفا شارع الحريري 1",
  },
  {
    title: "البريد الإلكتروني",
    desc: "aletihad.44@gmail.com , editor@alittihad44.com",
  },
  {
    title: "☎ هاتف",
    desc: "048605400(972+)",
  },
];

const socials: SocialType[] = [
  {
    id: "Facebook",
    name: "فيسبوك",
    icon: `<svg class="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_17_61)">
  <path d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 35.9789 8.77641 45.908 20.25 47.7084V30.9375H14.1562V24H20.25V18.7125C20.25 12.6975 23.8331 9.375 29.3152 9.375C31.9402 9.375 34.6875 9.84375 34.6875 9.84375V15.75H31.6613C28.68 15.75 27.75 17.6002 27.75 19.5V24H34.4062L33.3422 30.9375H27.75V47.7084C39.2236 45.908 48 35.9789 48 24Z" fill="currentColor"/>
  </g>
  </svg>
  `,
    slug: "https://www.facebook.com/Alittihad44",
  },

  {
    id: "Whatsapp",
    name: "وتساب",
    icon: `<svg class="w-5 h-5" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 308 308" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
     <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
     <g id="SVGRepo_iconCarrier"> <g id="XMLID_468_">
      <path id="XMLID_469_"
       d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"></path> <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"></path> </g> </g></svg>

  `,
    slug: "https://chat.whatsapp.com/ItnAVmN6pwe8YJfzfQY35b",
  },
];


const PageContact = () => {



  const [formInput, setFormInput] = useState<any>({
		name: '',
		email: '',
		message: '',
    
	});
  const [alert, setAlert] = useState<any>("");

	const handleInput = (e: any) => {
		setFormInput({
			...formInput,
			[e.target.name]: e.target.value,
		});
	};
const success = "تم إرسال الرسالة بنجاح، سنقوم بالرد عليكم بأسرع وقت ممكن"
const error = "حصل خطأ ما، يرجى تعبئة كل الخانات "
	const handleSubmit = (e: any) => {
		const formData = new FormData();
		formData.append('name', formInput.name);
		formData.append('email', formInput.email);
		formData.append('message', formInput.message);


		axios({
			url: 'https://alittihad44.com/contact.php',
			method: 'post',
			data: formData,
		})
			.then((responseSubmit) => {
				console.log('sending...', responseSubmit);
				if (responseSubmit.data.response === 1) {

          setAlert(success);
          console.log(alert)
				} else {
          setAlert(error);

				}
			})
			.catch((errorSubmit) => {
			console.log('error...', errorSubmit);
			});


	};






  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-28 ">
        <Heading2>اتصلوا بنا</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          يهمنا التواصل معنا في مختلف القضايا وفي مشاركة المواد لنشرها على الموقع
        </span>
      </header>

      {alert.length &&
                  <Alert  type="default" children ={alert}/>

      }

      <div className="grid gap-8 lg:grid-cols-2">
        <div className="max-w-sm space-y-6">
          {info.map((item, index) => (
            <div key={index}>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                {item.title}
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          ))}
          <div>
            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
              🌏 روابطنا الاجتماعية
            </h3>
            <SocialsList socials={socials}   className="mt-2"  />
          </div>
        </div>
        <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
        <div>
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit} >
            <label className="block">
              <Label>الاسم الكامل</Label>

              <Input
               placeholder="إدخال اسمك الكامل"
                type="text" 
                name="name"
                className="mt-1"
                value={formInput.name}
                onChange={handleInput} 
           
              />
            </label>
            <label className="block">
              <Label>البريد الالكتروني </Label>

              <Input
                type="email"
                name="email"
                placeholder="مثال: example@example.com"
                className="mt-1"
                value={formInput.email}
                onChange={handleInput}
              />
            </label>
            <label className="block">
              <Label>نص الرسالة</Label>

              <Textarea className="mt-1"
               rows={6}
               name="message"
               value={formInput.message} 
               onChange={handleInput}
             
               />
            </label>

            <ButtonPrimary type="submit">إرسال </ButtonPrimary>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default PageContact;
