import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

export interface PostCardLikeActionProps {
  className?: string;
  likeCount?: string;
}

const PostCardLikeAction: FC<PostCardLikeActionProps> = ({
  className = "px-3 h-8 text-xs",
  likeCount = 'نسخ الرابط',
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const location = useLocation();

  const hanldeClickDropDown = () => {
    
    const url = 'https://alittihad44.com'
      navigator.clipboard.writeText(
       url +  location.pathname
      );
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
      return;
   
  
  };
  
  return (
    <button

      className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors ${className} ${
        isCopied
          ? "text-rose-600 bg-rose-50 dark:bg-rose-100"
          : "text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500"
      }`}
      onClick={hanldeClickDropDown}

    >
     
      <svg
        width="24"
        height="24"
        fill={isCopied ? "currentColor" : "none"}
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
          clipRule="evenodd"
        ></path>
      </svg>
      {likeCount}
    </button>
  );
};

export default PostCardLikeAction;
