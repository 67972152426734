
import axios from 'axios'
import { AlittihadFeatures } from "./types";
import { useState, useEffect } from "react";


export default function GetHomePage() {

  const [features, setFeatures] = useState<AlittihadFeatures[]>([]);
  const [main, setMain] = useState<AlittihadFeatures[]>([]);
  const [breaking, setbreaking] = useState<AlittihadFeatures[]>([]);
  const [latest, setLatest] = useState<AlittihadFeatures[]>([]);
  const [latestmulhaq, setPost] = useState<AlittihadFeatures[]>([]);
  const [mainmulhaq, setmainmulhaq] = useState<AlittihadFeatures[]>([]);
  const [videos, setVideos] = useState<AlittihadFeatures[]>([]);
  const [audio, setAudio] = useState<AlittihadFeatures[]>([]);
  const [writers, setWriters] = useState<AlittihadFeatures[]>([]);


  useEffect(() => {
   
    fetchData();
  }, []);


  
    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v3/HomePage"
      );
      setFeatures(response.data.features);
      setMain(response.data.main);
      setbreaking(response.data.breaking);
      setPost(response.data.latestmulhaq);
      setLatest(response.data.latest);
      setmainmulhaq(response.data.mainmulhaq);
      setVideos(response.data.videos);
      setAudio(response.data.audio);
      setWriters(response.data.writers);


    
     
      

   };

return {latestmulhaq, latest, features, main, breaking, mainmulhaq, videos, audio, writers} ;
     

}

