import { useLocation } from "react-router-dom"




export default function GetMetaData() {

    
const location = useLocation()
const metaTitle = "جريدة الاتحاد"
const metaDesc = " الموقع الإلكتروني لجريدة الاتحاد، اخر الأخبار وأهمها على مدار الساعة"
const metaImage = "https://alittihad44.com/"
const metaUrl = location.pathname

return {metaTitle , metaDesc, metaImage, metaUrl  };
}

