import React, { useState, useEffect } from "react";
import GetCats from "data/cats";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import ButtonSecondary from "components/Button/ButtonSecondary";
import NcImage from "components/NcImage/NcImage";
import { GlobeAltIcon, ShareIcon } from "@heroicons/react/24/outline";
import NcDropDown from "components/NcDropDown/NcDropDown";
import { SOCIALS_DATA } from "components/SocialsShare/SocialsShare";
import AccountActionDropdown from "components/AccountActionDropdown/AccountActionDropdown";
import Image from "components/Image";
import axios from 'axios'
import { AlittihadFeatures } from "data/types";
import { useParams, useLocation } from "react-router";
import Link from "components/Link";
import ReactPaginate from "react-paginate";
import twFocusClass from "utils/twFocusClass";
import Heading from "components/Heading/Heading";


export interface SocialType {
  id: string;
  name: string;
  icon: string;
  slug: string;
}
const PageAuthor = () => {
  const params = useParams();
const location = useLocation();

const [pageNews, setPageNews] = useState(0);
const [pageMulhaq, setPageMulhaq] = useState(0);
const [writers, setWritersNews] = useState<AlittihadFeatures[]>([]);
  const [mulhaqs, setWritersMulhaq] = useState<AlittihadFeatures[]>([]);
  const [isHover, setIsHover] = useState(false);
  const [totalNews, setTotalNews] = useState(0);
  const [totalMulhaq, setTotalMulhaq] = useState(0);


  useEffect(() => {
   
    fetchData();
  }, [pageNews]);


    const fetchData = async () => {
      const writer = await axios.get(
        "https://alittihad44.com/api/v3/writer/"+ params.href+"?page="+ pageNews
      );

      const writerMulhaq = await axios.get(
        "https://alittihad44.com/api/v3/writer/"+ params.href+"?page="+ pageMulhaq
      );



      setWritersNews(writer.data.news.data);
      setWritersMulhaq(writerMulhaq.data.mulhaq.data);

      setTotalNews(writer.data.news.last_page)
      setTotalMulhaq(writerMulhaq.data.mulhaq.last_page)


      console.log(totalNews)
      console.log(totalMulhaq)

    };
 
    const changeNewsPage = ({ selected }:any) => {
      setPageNews(selected);
  };

  const changMulhaqPage = ({ selected }:any) => {
    setPageMulhaq(selected);
};

  return (
    <div className={`nc-PageAuthor `}>
      {/* HEADER */}
      <div className="w-full">
   
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            alt=""
            containerClassName="absolute inset-0"
            sizes="(max-width: 1280px) 100vw, 1536px"
            src="https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="object-cover w-full h-full"
            fill
          />
        </div>
  
        <div className="container -mt-10 lg:-mt-16">
         
        {writers.filter((_, i) => i < 1).map(({ uimage, name, about_writer })  => ( 
          <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
            <div className="w-32 lg:w-40 flex-shrink-0 mt-12 sm:mt-0">
              <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold rounded-full w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 shadow-2xl z-0">
                <Image
                  alt="Avatar"
                  src={'https://alittihad44.com/images/users/'+uimage}
                  fill
                  className="object-cover"
                />
              </div>
            </div>

            {/*  */}
            <div className="pt-5 md:pt-1 lg:ml-6 xl:ml-12 flex-grow">
              <div className="max-w-screen-sm space-y-3.5 ">
                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  <span>{name}</span>
                  
            {/* 
                  <VerifyIcon
                    className="ml-2"
                    iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
                  />
                   */}
                </h2>
                <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                  {about_writer}
                </span>
                <a
                  href="##"
                  className="flex items-center text-xs font-medium space-x-2.5 cursor-pointer text-neutral-500 dark:text-neutral-400 truncate"
                >
                  <GlobeAltIcon className="flex-shrink-0 w-4 h-4" />
                  <span className="text-neutral-700 dark:text-neutral-300 truncate">
                    {'https://alittihad44.com'+location.pathname}
                  </span>
                </a>
                <nav
      className={`nc-SocialsList flex space-x-3 text-2xl text-neutral-6000 dark:text-neutral-300 block`}
    >
      {SOCIALS_DATA.map((item, i) => (
        <a
          key={i}
          className={`block`}
          href={item.slug  + 'https://alittihad44.com'+ location.pathname}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <div dangerouslySetInnerHTML={{ __html: item.icon || "" }}></div>
        </a>
      ))}
    </nav>
              </div>
            </div>

            {/*  */}
            <div className="absolute md:static left-5 right-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex justify-end">
              

              <div className="mx-2">
                <NcDropDown
                  className="flex-shrink-0 flex items-center justify-center focus:outline-none h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
                  renderTrigger={() => <ShareIcon className="h-5 w-5" />}
                  onClick={(item: any) =>
                    (
                     window.open(item.slug + 'https://alittihad44.com'+ location.pathname), '_blank', 'noopener'     
                     )}
                     data={SOCIALS_DATA}
                />
              </div>

              <AccountActionDropdown containerClassName="h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700" />
            </div>
          </div>
          ))}
        </div>


      </div>
      {/* ====================== END HEADER ====================== */}
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
      <Heading children="مقالات من الكاتب/ة" isCenter={true}   />
        <main>
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {mulhaqs.map(({ id, stitle, slug, slugimage, curl })  => (
                     <div
                     className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 h-full`}
                     onMouseEnter={() => setIsHover(true)}
                     onMouseLeave={() => setIsHover(false)}
                     //
                   
                   >
                     <div
                       className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 aspect-w-4 aspect-h-3`}
                     >
                       <div>
                          <div className={`nc-PostFeaturedMedia relative h-full`}>
                  
                       <Image
                         alt="featured"
                         fill
                         className="object-cover"
                         src={"https://alittihad44.com/" +slugimage}
                         sizes="(max-width: 600px) 480px, 800px"
                       />
                  
               
                       <Link
                         href={"/"+curl+"/"+slug}
                         className={`block absolute inset-0 bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
                          
                         }`}
                       />
                  
                   </div> 
                       </div>
                     </div>
                     <Link href={"/"+curl+"/"+slug} className="absolute inset-0"></Link>
                     <span className="absolute top-3 inset-x-3 z-10">
                      {/*} <CategoryBadgeList categories={categorie_id} /> */}
                     </span>
               
                     <div className="p-4 flex flex-col space-y-3">
                     {/*
                      {!hiddenAuthor ? (
                         <PostCardMeta meta={post} />
                       ) : (
                         <span className="text-xs text-neutral-500">{updated_at}</span>
                       )}
               
                      */}
                       <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                         <span className="line-clamp-2" title={stitle}>
                           {stitle}
                         </span>
                       </h3>
                      
                     </div>
                   </div>
            ))}
          </div>

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
          <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
    >
          <ReactPaginate
          
            pageRangeDisplayed={3} //The range of buttons pages displayed.
            previousLabel={"السابق"} //lable for previous page button
            nextLabel={"التالي"} // lable for Next page button
            pageCount={totalMulhaq} // place here the variable for total number of pages
            onPageChange={changMulhaqPage} // trigger event function
            /// navigation styling ///
            containerClassName={`nc-Pagination inline-flex space-x-1 text-base font-medium `}
            previousLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            nextLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            disabledClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            activeClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white focus:outline-none ${twFocusClass()}`}
            activeLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-red focus:outline-none ${twFocusClass()}`}
            pageLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}

            /// end navigation styling ///
        />
        </nav>
          
          </div>
        </main>
      </div>
      <div className="w-full">
   



 </div>

 {/* ====================== END HEADER ====================== */}

 <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
 <Heading children="أخبار من الكاتب/ة" isCenter={true}   />

   <main>
     {/* TABS FILTER */}
  

     {/* LOOP ITEMS */}
     <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
       {writers.map(({ id, stitle, slug, slugimage, curl })  => (
                <div
                className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 h-full`}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                //
              >
                <div
                  className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 aspect-w-4 aspect-h-3`}
                >
                  <div>
                     <div className={`nc-PostFeaturedMedia relative h-full`}>
             
                  <Image
                    alt="featured"
                    fill
                    className="object-cover"
                    src={"https://alittihad44.com/" +slugimage}
                    sizes="(max-width: 600px) 480px, 800px"
                  />
             
          
                  <Link
                    href={"/"+curl+"/"+slug}
                    className={`block absolute inset-0 bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
                     
                    }`}
                  />
             
              </div> 
                  </div>
                </div>
                <Link href={"/"+curl+"/"+slug} className="absolute inset-0"></Link>
                <span className="absolute top-3 inset-x-3 z-10">
                 {/*} <CategoryBadgeList categories={categorie_id} /> */}
                </span>
          
                <div className="p-4 flex flex-col space-y-3">
                {/*
                 {!hiddenAuthor ? (
                    <PostCardMeta meta={post} />
                  ) : (
                    <span className="text-xs text-neutral-500">{updated_at}</span>
                  )}
          
                 */}
                  <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                    <span className="line-clamp-2" title={stitle}>
                      {stitle}
                    </span>
                  </h3>
                 
                </div>
              </div>
       ))}
     </div>

     {/* PAGINATION */}
     <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
     <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
>
     <ReactPaginate
     
       pageRangeDisplayed={3} //The range of buttons pages displayed.
       previousLabel={"السابق"} //lable for previous page button
       nextLabel={"التالي"} // lable for Next page button
       pageCount={totalNews} // place here the variable for total number of pages
       onPageChange={changeNewsPage} // trigger event function
       /// navigation styling ///
       containerClassName={`nc-Pagination inline-flex space-x-1 text-base font-medium `}
       previousLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
       nextLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
       disabledClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
       activeClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white focus:outline-none ${twFocusClass()}`}
       activeLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-red focus:outline-none ${twFocusClass()}`}
       pageLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}

       /// end navigation styling ///
   />
   </nav>
     
     </div>
   </main>

   {/* === SECTION 5 === */}
   <div className="relative py-16">
     <BackgroundSection />
     <SectionGridCategoryBox
       categories={GetCats().filter((_, i) => i < 10)}
     />
     <div className="text-center mx-auto mt-10 md:mt-16">
       <ButtonSecondary>Show me more</ButtonSecondary>
     </div>
   </div>

   {/* === SECTION 5 === 
   <SectionSliderNewAuthors
     heading="Top elite authors"
     subHeading="Discover our elite writers"
     authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
   />
*/}
   {/* SUBCRIBES */}
   <SectionSubscribe2 />
 </div>

    </div>
  );
};

export default PageAuthor;
