import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import { AlittihadCategories } from "data/types";
import Link from "components/Link";

export interface CardCategory1Props {
  className?: string;
  taxonomy: AlittihadCategories;
  size?: "large" | "normal";
}

const CardCategory1: FC<CardCategory1Props> = ({
  className = "",
  size = "normal",
  taxonomy,
}) => {
  
  const { id, ctitle, curl } = taxonomy;
  return (
    <Link
      href={"/"+ curl}
      className={`nc-CardCategory1 flex items-center ${className}`}
    >
      <NcImage
        alt=""
        containerClassName={`relative flex-shrink-0 ${
          size === "large" ? "w-20 h-20" : "w-12 h-12"
        } rounded-lg mr-4 overflow-hidden`}
        src={"https://www.alittihad44.com/images/news/2020.06.14.18.06.47-2019.11.26.01.14.16-5c3b098e68e82.png" || ""}
        fill
        className="object-cover"
        sizes="80px"
      />
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold px-2`}
        > 
          {ctitle}
        </h2>
        <span
          className={`${
            size === "large" ? "text-sm" : "text-xs"
          } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
        >
        
        </span>
      </div>
    </Link>
  );
};

export default CardCategory1;
