
import axios from 'axios'
import { AlittihadFeatures } from "./types";
import { useState, useEffect } from "react";
import { auther } from './types';

export default function GetEditors() {

  const [writer, setWriter] = useState<auther[]>([]);



  useEffect(() => {
   
    fetchData();
  }, []);


  
    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v3/writers/editors"
      );
      setWriter(response.data);

      

   };

return writer;
     

}

