import Heading from "components/Heading/Heading";
import NcImage from "components/NcImage/NcImage";
import GetEditors from "data/editors";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}



const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
       
      >
         طاقم التحرير
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {GetEditors().map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              alt="founder"
              fill
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden z-0"
              className="absolute inset-0 object-cover"
              src={item.uimage}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.about_writer}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
