import React, { FC } from "react";
import NcModal from "components/NcModal/NcModal";
import Tag from "components/Tag/Tag";
import { Tags } from "data/types";
import Button from "components/Button/Button";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import GetTags from "data/tags";
export interface ModalTagsProps {
  tags: Tags[];
}

const ModalTags: FC<ModalTagsProps> = ({ tags }) => {
  const renderModalContent = () => {
    return (
      <div className="flex flex-wrap dark:text-neutral-200" style={{direction: 'rtl'}}>
        {GetTags().tags.map((tag) => (
          <Tag key={tag.id} tag={tag} className="mr-2 mb-2" />
        ))}
      </div>
    );
  };

  return (
    <div className="nc-ModalTags px-2" >
      <NcModal
        contentExtraClass="max-w-screen-md"
        renderTrigger={(openModal) => (
          <Button
            pattern="third"
            fontSize="text-sm font-medium"
            onClick={openModal}
          >
            <div>
              <span className=" sm:inline">وسوم</span> / Tags
            </div>
            <ChevronDownIcon
              className="w-4 h-4 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Button>
        )}
        modalTitle="تصفح في الوسوم"
        renderContent={renderModalContent}
      />
    </div>
  );
};

export default ModalTags;
