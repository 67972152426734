import { FC, useState } from "react";
import { AlittihadFeatures } from "data/types";
import Link from "components/Link";
import Image from "components/Image";
import Badge from "components/Badge/Badge";

export interface Card11Props {
  className?: string;
  post: AlittihadFeatures;
  ratio?: string;
  hiddenAuthor?: boolean;
}

const Card11: FC<Card11Props> = ({
  className = "h-full",
  post,
  hiddenAuthor = false,
  ratio = "aspect-w-4 aspect-h-3",
}) => {
  const { slugimage, stitle, curl, categorie_id, updated_at, ctitle, id, slug } = post;

  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
           <div className={`nc-PostFeaturedMedia relative ${className}`}>
   
        <Image
          alt="featured"
          fill
          className="object-cover"
          src={"https://alittihad44.com/" +slugimage}
          sizes="(max-width: 600px) 480px, 800px"
        />
   

        <Link
          href={'/'+curl +'/'+ slug}
          className={`block absolute inset-0 bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
           
          }`}
        />
   
    </div> 
        </div>
      </div>
      <Link href={'/'+curl +'/'+ slug} className="absolute inset-0"></Link>
      <span className="absolute top-3 inset-x-3 z-10">
      <div
      className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}
      data-nc-id="CategoryBadgeList">
        <Badge
          key={id}
          name={ctitle}
          href={'/'+curl}
          color={'red'}
        />
    </div>
      </span>

      <div className="p-4 flex flex-col space-y-3">
      {/*
       {!hiddenAuthor ? (
          <PostCardMeta meta={post} />
        ) : (
          <span className="text-xs text-neutral-500">{updated_at}</span>
        )}

       */}
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={stitle}>
            {stitle}
          </span>
        </h3>
 
      </div>
    </div>
  );
};

export default Card11;
