import React, { FC } from "react";
import { AlittihadFeatures } from "data/types";
import Badge from "components/Badge/Badge";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image";
import Avatar from "components/Avatar/Avatar";

export interface Card6Props {
  className?: string;
  post: AlittihadFeatures;
  hiddenAvatar?: boolean;
  avatarSize?: string;

}

const Card6: FC<Card6Props> = ({ 
  hiddenAvatar = false,
  className = "h-full",
  avatarSize = "h-7 w-7 text-sm",

   post
   }) => {
  const {id, stitle, slug, ctitle, curl , slugimage, updated_at, post_type, href, uimage, name } =
    post;
    let date = new Date(updated_at);
var months = ["كانون ثاني/يناير", "شباط/فبراير", "آذار/مارس", "نيسان/إبريل", "أيار/مايو", "حزيران/يونيو",
  "تموز/يوليو", "آب/أغسطس", "أيلول/سبتمبر", "تشرين أول/أكتوبر", "تشرين ثاني/نوفمبر", "كانون أول/ديسمبر"
];
var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] ;


  return (
    <div
      className={`nc-Card6 relative flex group flex-row items-center sm:p-4 sm:rounded-3xl sm:bg-white sm:dark:bg-neutral-900 sm:border border-neutral-200 dark:border-neutral-700 ${className}`}
    >
      <Link href={curl + '/' +slug} className="absolute inset-0 z-0"></Link>
      <div className="flex flex-col flex-grow">
        <div className="space-y-3 mb-4">
         <div className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}>
         <Badge
          key={id}
          name={ctitle}
          href={curl}
          color={'red'}
        />
         </div>
          <h2 className={`block font-semibold text-sm sm:text-base`}>
            <Link href={curl + '/' + slug} className="line-clamp-2" title={stitle}>
              {stitle}
            </Link>
          </h2>
                {/*  avatar  */}

    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 leading-none text-xs`}
    >
      <Link href={'writer/'+ href} className="relative flex items-center space-x-2">
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={'https://alittihad44.com/images/users/'+uimage}
            userName={name}
          />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {name}
        </span>
      </Link>
      <>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {delDateString}
        </span>
      </>
    </div>
        {/*  end avatar  */}
        </div>
        <div className="flex items-center flex-wrap justify-between mt-auto">
         {/*  <PostCardSaveAction className="relative" readingTime={readingTime} /> */}
        </div>
      </div>

      <Link
        href={curl + '/' +slug}
        className={`block relative flex-shrink-0 w-24 h-24 sm:w-40 sm:h-full rounded-2xl overflow-hidden z-0`}
      >
        <Image
          sizes="(max-width: 600px) 180px, 400px"
          className="object-cover w-full h-full"
          fill
          src={'https://alittihad44.com/' + slugimage}
          alt={stitle}
        />
        <span className="absolute bottom-1 left-1">
          <PostTypeFeaturedIcon
            wrapSize="h-7 w-7"
            iconSize="h-4 w-4"
            postType={post_type}
          />
        </span>
      </Link>
    </div>
  );
};

export default Card6;
