import Cardbreaking from "components/cardbreaking/cardbreaking";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { FC } from "react";
import { AlittihadFeatures } from "data/types";
import GetBreaking from "data/breaking";



export interface WidgetPostsProps {
  className?: string;
  posts?: AlittihadFeatures[];
}

const WidgetPosts: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",

}) => {



  
  return (
    <div className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title="🔔  الأخبار العاجلة "
        viewAll={{ label: "المزيد ", href: "/breaking" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {GetBreaking() && GetBreaking().filter((_, i) => i >= 0 && i < 7).map((post) => (
          <Cardbreaking
            className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
            key={post.id}
            post={post}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetPosts;
