
import axios from 'axios'
import { AlittihadFeatures } from "./types";
import { useState, useEffect } from "react";


export default function GetBreaking() {

  const [Breakings, setBreaking] = useState<any[]>([]);


  useEffect(() => {
   
    fetchData();
  }, []);


  
    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v3/Breakings"
      );
      setBreaking(response.data);
   

   };

return Breakings ;
     

}

