import React, { FC } from "react";
import { AlittihadFeatures } from "data/types";
import Link from "components/Link";
import ButtonPlayMusicPlayer from "../ButtonPlayMusicPlayer";
import Image from "components/Image";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";

export interface Card15PodcastProps {
  className?: string;
  post: AlittihadFeatures;
}

const Card15Podcast: FC<Card15PodcastProps> = ({
  className = "h-full",
  post,
}) => {
  const {id, stitle, slug, href, curl , slugimage, name, post_type, uimage } =
    post;
  const IS_AUDIO = post_type === "audio";

  const renderDefaultBtnListen = (state?: "playing") => {
    return (
      <div className="inline-flex items-center mt-3 pr-4 py-0.5 hover:pl-0.5 cursor-pointer rounded-full transition-all hover:bg-primary-50 dark:hover:bg-neutral-900">

      </div>
    );
  };

  return (
    <div
      className={`nc-Card15Podcast relative flex group items-center p-3 rounded-3xl border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900 ${className}`}
    >
      <div className="w-1/4 flex-shrink-0">
        <Link
          href={"/mulhaq/"+curl +"/"+ slug } 
          className="block h-0 aspect-w-1 aspect-h-1 relative rounded-full overflow-hidden shadow-lg"
        >
          <Image
            className="object-cover w-full h-full"
            src={'https://alittihad44.com/images/users/'+uimage}
            fill
            alt={stitle}
            sizes="100px"
          />
        </Link>
      </div>

      <div className="flex flex-col flex-grow" style={{marginRight: '1rem'}}>
        <h2 className={`nc-card-title block font-semibold text-sm sm:text-lg`}>
          <Link
            href={"/mulhaq/"+ curl +"/"+ slug } 
            className={IS_AUDIO ? `line-clamp-1` : "line-clamp-2"}
            title={stitle}
          >
            {stitle}
          </Link>
        </h2>
        <Link  href={"/writer/"+ href} >
        <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1 ">
          {name}
        </span>
        </Link>
    
      </div>
    </div>
  );
};

export default Card15Podcast;
