import Card15Podcast from "components/Card15Podcast/Card15Podcast";
import Card9 from "components/Card9/Card9";
import Heading from "components/Heading/Heading";
import { AlittihadFeatures } from "data/types";
import React, { FC } from "react";


export interface SectionMagazine9Props {
  posts: AlittihadFeatures[];
  className?: string;
  gapClassName?: string;
  heading?: string;
}

const SectionMagazine9: FC<SectionMagazine9Props> = ({
  posts ,
  className = "",
  gapClassName = "gap-6 md:gap-8",
  heading = "مقالات مختارة",
}) => {

  return (
    <div className={`nc-SectionMagazine9 relative ${className}`}>
      {heading && (
        <Heading >
          {heading}
        </Heading>
      )}
   
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ${gapClassName} mt-8`}
      >
        {posts
          
          .map((p, index) => (
            <Card15Podcast key={index} post={p} />
          ))}
      </div>
    </div>
  );
};

export default SectionMagazine9;
