import React, { FC, useState, useEffect } from "react";
import imgAdsDef from "images/ads.png";
import Image from "../Image";
import { advirt } from "data/types";
import axios from 'axios'


export interface SectionAdsProps {
  className?: string;
  imgAds?: string;
}


const SectionAds: FC<SectionAdsProps> = ({
  className = "",
  imgAds = imgAdsDef,
}) => {

  const [mainAdvirt, setmainAdvirt] = useState<advirt[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   
    fetchData();
  }, []);


  
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        "https://alittihad44.com/api/v3/adverts"
      );


  setmainAdvirt(response.data);

    }
  return (
     <div>
    {mainAdvirt.map((item, index) =>

    <a key={index}
      href={item.advert_url}
      className={`nc-SectionAds block text-center mx-auto ${className}`}
    >
      <span className="text-xs text-neutral-500">- إعلان -</span>
      <Image className="mx-auto" src={'https://alittihad44.com/images/adverts/'+item.image_path} alt="ads" />
    </a>

    )}
    </div>
  );
};

export default SectionAds;
