import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from "./types";
import Footer from "components/Footer/Footer";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import PageHome from "app/(home)/page";
import PageArchive from "app/(archives)/archive/page";
import PageAuthor from "app/author/page";
import PageSingle from "app/(singles)/(default)/single/page";
import PageMulhaq from "app/(singles)/(default)/single/mulhaq";
import PageSearch from "app/(search)/search/page";
import PageAbout from "app/about/page";
import PageContact from "app/(others)/contact/page";
import Page404 from "app/not-found";
import SiteHeader from "app/SiteHeader";
import Mulhaqpage from "app/(archives)/archive/Mulhaqpage";
import PageTags from "app/tags/page";




export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/", component: PageHome },
  { path: "/:curl/:slug/", component: PageSingle },
  { path: "/mulhaq/:curl/:slug/", component: PageMulhaq },
  { path: "/writer/:href", component: PageAuthor },
  { path: "/tags/:tags", component: PageTags },

  { path: "/:curl/", component: PageArchive },
  { path: "/mulhaq/:curl/", component: Mulhaqpage },


  // archives page -------------------------------------------------------


  // search -------------------------------------------------------
  { path: "/search", component: PageSearch },

  // other pages -------------------------------------------------------
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },


];

const MyRoutes = () => {

  
  return (
    
    <BrowserRouter >
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path}/>;
        })}
        <Route element={<Page404 />} />
      </Routes>

      <Footer />
      <MusicPlayer />
    </BrowserRouter>
  );
};

export default MyRoutes;
