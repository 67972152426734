import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionMagazine1 from "components/Sections/SectionMagazine1";
import SectionAds from "components/Sections/SectionAds";
import SectionGridPosts from "components/Sections/SectionGridPosts";
import SectionMagazine8 from "components/Sections/SectionMagazine8";
import SectionCategory from "components/Sections/SectionCategory";
import SectionTrending from "components/Sections/SectionTrending";
import CardLarge1 from "components/CardLarge1/CardLarge1";
import GetHomePage from "data/home";
import Heading from "components/Heading/Heading";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import GetMetaData from "data/metadata";
import SectionMagazine9 from "components/Sections/SectionMagazine9";


const PageHome = () => {

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

 
  return (



<div> 

    <div className="nc-PageHome relative">

        <Helmet>
        <title>{GetMetaData().metaTitle}</title>
        <meta name="description" content={GetMetaData().metaDesc} />
        <meta property="og:title" content={GetMetaData().metaTitle} data-react-helmet="true" />
        <meta property="og:description" content={GetMetaData().metaDesc} data-react-helmet="true" />
        <meta property="og:type" content="article" data-react-helmet="true" />
        <meta property="og:url" content={'https://alittihad44.com'+GetMetaData().metaUrl} data-react-helmet="true" />
        <meta property="og:image" content={GetMetaData().metaImage + 'images/news/2020.06.14.18.06.47-2019.11.26.01.14.16-5c3b098e68e82.png' } data-react-helmet="true" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="900" /> 
        <meta property="og:image:width" content="350" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={GetMetaData().metaImage + 'images/news/2020.06.14.18.06.47-2019.11.26.01.14.16-5c3b098e68e82.png'}/>


      </Helmet>
      <div className="container relative " >
  
      <div className={'nc-SectionLargeSlider relative pt-4 pb-4 md:py-4 lg:pb-4 lg:pt-4'} >
   
       {  GetHomePage().features.filter(
       (p, i) => i ==0 ).map((item, index) => {
        return (
          <CardLarge1
            key={index}
            post={item}

          />
        );
      })}
    </div>
 

      <SectionMagazine1 className="pb-4" posts={GetHomePage().mainmulhaq} />

      <SectionAds className="relative py-16 pt-20" />
    
 {/*  مقالات رئيسية    */}
 <div className="relative py-16">
          <BackgroundSection />
          <SectionMagazine9
            posts={GetHomePage().mainmulhaq.filter((_, i) => i  < 3)}
          />
        </div>

        

       {/*  الزوايا   */}    
 <SectionCategory  className="py-4 lg:py-4" posts={GetHomePage().latest} 
        />

        <SectionTrending
              heading="✍🏽 مقالات وآراء"
              className="py-4 lg:py-4"
              posts={GetHomePage().latestmulhaq.filter((_, i) => i < 6)}
             
            />
        
      </div>
      {GetHomePage().videos.length &&

      <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100">
        <div className="relative container">
          <SectionGridPosts
            className="py-4 lg:py-4"
            headingIsCenter
            postCardName="card10V2"
            heading="زاوية الفيديوهات"
            subHeading="قائمة منوعة من الفيديوعات على يوتيوب 🥡"
            gridClass="md:grid-cols-2 lg:grid-cols-3"
          />
        </div>
      </div>
}
      {GetHomePage().audio.length && 
      <div className="container ">

        <SectionMagazine8
          className="py-4 lg:py-4"
          
        />
      </div>
      }

    <div className="container relative py-28">
    
    <div className={`nc-SectionGridAuthorBox relative`}>
    
      <Heading desc="اختيار عشوائي من كتّاب الموقع" isCenter>
        كتّاب موقع الاتحاد
      </Heading>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8 ">
        {GetHomePage().writers.map((author) => (
          <CardAuthorBox key={author.id} author={author} />
        ))}
      </div>
    </div>

</div>


      
    </div>
</div>
 

  );
};

export default PageHome;


