import React from "react";

const LogoSvg = () => {
  return (

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="91" 
	 viewBox="0 0 769.9 293.3">
<g>
	<path fill="#CC1F36" d="M741.4,6.7c3.2,12.2,8.8,23.5,13.6,35.3c0.1,2.1-0.6,4.1-2.1,5c-3.1,1-5.5-2-7.4,0.2c-1.4,5.1-0.3,11,1,16.2
		c3.1,15.8,6.2,32.2,10.2,48.1c6.2,22.6,7.8,47.3,12.9,70.8c-0.3,16.1,2.8,33.6-4.5,47.7c-1.7,1.4-4.5,2.6-6.2,1.2l-1.2-1.9
		c3.1-21.6-1.9-42.6-4.5-62.9c-6.8-30.9-14.2-61.3-19.3-92.4c-5.1-17.4-4.2-37.4-7.4-55c0.9-6,2.8-11.8,6.2-16.9
		c1.3-1.6,3.5-2.5,6-1.7L741.4,6.7L741.4,6.7z"/>
	<path fill="#CC1F36" d="M688,15.8c2.5,9.5,14.3,17.5,7.2,27.2c-3.5,1.6-6.3-5.4-7.6-0.2c2.7,10.9,2.7,23,5.5,34.1
		c5.3,25.7,13.4,50.6,17.9,76.7c2.7,13.5,2.1,28.5,1.4,42.9c-7.3,24.4-22.5,47.9-45.5,62.9c-9.3,4.7-20.5,4.1-30.3,6.9
		c-7,1.2-13.8,3.6-20.5,3.1c-1.5-0.9-1.8-2.6-0.7-3.6c28.7-15.4,58.1-30.5,80.8-57.2c10.3-11-4.9-20.2-8.6-29.5
		c-28.7-30.6-61.2-55.9-90.8-83.2c-14.3-13.7-28.2-29.8-39.5-46c-6-8.9-14.6-15.3-21-23.8c-2-8.4-7.9-18.3,1.9-21.9
		c13.7,1.5,22.5,13.6,33.6,22.4c1.2,2.2,3.3,5.1,1.7,7.9c-2.3,3.9-6-1.1-9,2.9c22.9,32.5,54.5,57.4,85.3,83.6
		c18.2,16.4,39,33.6,50.3,55.3c-0.1-23.3-9.7-43.1-13.1-65c-6.3-26.3-12.2-51.8-14-80.8c0.1-10.2-3.1-22.6,7.1-29.3
		C686.7,2.7,685.4,9.4,688,15.8"/>
	<path fill="#CC1F36" d="M507,5.5c-1.1,8-3.4,17.6-12.1,20.2c-17.8,8.9-35.7,16.6-53.1,25.5l-78.6,40l-147,73.9
		c-4.6,2.2-9.2,6.2-14.3,4.8c-0.9-4.4,0.2-9.1,0.9-13.3c1.7-7.6,10.1-11.4,15.7-16c6.2-5,15.6-6.1,19.3-13.8
		c3.2-15.8,6.3-34.7,2.4-50.5c-3-13.6-10.2-25.4-20.2-36c-4.9-5.5-15.6-9.7-11.7-18.8c2.4-4.2,2.5-10.3,8.3-12.4
		c2.3-0.9,5.3-0.5,7.4,0.9c14.9,11.8,25.4,28.5,27.6,47.2c2.8,19.9,1.5,40,0.2,60.5l0.9,0.9c49.6-21.7,98.2-46.1,147-70.3
		c4-1.6,3-6.2,2.6-9.3c-2.1-8.2-5.3-15.9-9.8-22.9c-0.3-3.2,0.7-6.5,4-7.6c3.3-0.6,5.6,1.5,8.1,3.8c6,7.8,2.5,19.8,8.8,27.6l1.4,0.5
		l86-38.1C503.3,1.5,506.7,2.8,507,5.5"/>
	<path fill="#CC1F36" d="M652.5,14.8c-0.8,2.5-3.4,3.4-4.8,5c-3.8-2.5-6.5-2.6-8.6,0.7c-4.5,7.6-2.6,17.9-6.2,25.5
		c-1.4,1.5-3.2,0.8-4.3,0.2c-5.8-4.3-11-23.5-16-13.1c-5.5-1.3-6.7-5.9-8.8-9.8c-0.1-4.5-0.2-10,5-11.7c9.9-0.6,11.8,11,17.6,16.7
		c1.5,0.8,3.5-0.1,4.3-1.9c-1-7.9-1-18.1,7.4-22.4c2-0.8,5-0.6,7.2,0.7C648.9,7.3,650.7,11.2,652.5,14.8"/>
	<path fill="#CC1F36" d="M94.8,16.2c1.6,6.6-2.5,11.5-4.5,16.9C84,44,73.2,51.7,61.9,55.1c-2.5,0.5-5.6,0.5-6.9-2.1
		c0-8.5,11.7-10,15.5-15.7c0.4-1.8-1.6-3.2-2.9-3.6c-7.9-3.4-15.7,4-22.9,2.4c-2.7-2.8-3-6.6-1.9-10.2c6-15,23.8-12.9,37.4-15.2
		C85.9,10.4,92.3,11.1,94.8,16.2"/>
	<path fill="#CC1F36" d="M166.1,13.9c7.9,28.9,12.6,59,16,89.8c2.1,35.3,5.8,70.3,8.8,105.1c2.9,16.8,14.4,35,31.2,41.4
		c13.1,5.8,29,6,43.6,8.1c42.8,1.6,83.7-4.2,124.6-8.8c35.3-3,68.9-12.1,102-21.7c18-5.7,34.8-14.6,51.9-23.1l-1.4-1.4
		c-7.5-2.3-14.8-4.2-22.2-5.7c-13.4-1.6-28.2-0.7-40.3-5.2c-2.9-1.6-2.3-4.6-2.1-7.1c3-18.5,23.9-17.6,35.7-27.4
		c14-8,30.8-14.4,40.5-29.3c3.7-9.4,9.1-18.1,15.5-26.4c2.3-2.2,6.9-3.2,10-0.9c5.9,4.3,7.3,11.4,8.3,18.3
		c0.3,5.7,1.6,11.3,1.7,17.4c-0.7,1.2-1.5,2.9-2.9,3.1c-7.1-0.2-4.5-8.4-6.9-12.4c-1.1-2-2.7-3.8-4.5-4.3
		c-9.5,2-14.7,12.5-18.3,20.2c-5.7,13-19.3,22.4-31,29.1c-1.2,1.1-3.1,2.1-2.2,3.8c9.2,2.7,19.2,4.2,29.5,5.2
		c37.4,1.6,73.7,11.5,112.5,6.2c1.1,0.3,2.6,0.8,3.6,2.4l-0.5,3.3c-6,5.9-7.7,15.1-15.5,18.8c-15.7,2.6-31.2-0.1-46.7,0.5l-17.4,2.6
		c-37.1,15.6-75.8,27.1-113.4,40.7c-2.7,3.2-5.7,6.8-3.6,11.4c1.4,3,6.1,5.3,3.1,9.3c-1.5,2.3-4.2,1.3-6,1c-3.8-1.6-4.1-5.7-4.3-9.1
		c-0.4-3.3,3.3-6.5,0.5-9c-15.8,2.3-30.8,7.3-46.5,10c-32.3,7.8-66.1,11.7-100.1,15c-35.2,0.2-68.7-1.2-99.6-10.7
		c-15.3-7.1-27.6-21.9-32.9-37.2c-15.6-27.2-9.6-60.9-14.3-92.2c-2.3-22.9-7-43.8-8.8-66.7c-1.7-15.5-6.8-29.6-10.7-44.1
		c-1.7-8.3,3-15,6.2-21.9C161.2,9.7,165.6,10.7,166.1,13.9"/>
	<path fill="#CC1F36" d="M124.8,14.8c0.7,6.7-11.1,16.4,0,19.3c1.8,2.5,0.1,5.9-0.7,8.6c-1.8,2.7-5.4,1-7.6,0.5
		c-7.9-9.1,0.1-20.3,3.1-29.3C120.9,12.1,124.8,11.7,124.8,14.8"/>
	<path fill="#CC1F36" d="M335.2,18.9c0.6,1.8,1.6,4.1,0.7,6.2c-1.4,2.7-4.2,4-6.9,4c-2.1-0.7-5.1-1.3-6.9,0c-5.7,8.4-1.7,20.9-6.9,29.5
		c-1.5,1.9-4.1,1.5-5.7,0.5L300.9,46c-5.5-0.8-11.7-2.5-14.1-7.9c-1.5-3.7,0.2-7.2,1.7-10.2c2.1-2.5,6-2.5,9-1.7
		c3.8,3.2,8.3,7.7,12.4,11.4c1.8,0.1,2.3-2,2.9-3.3c2.6-7,2.5-15.9,10.2-20C328,13.4,332,15.4,335.2,18.9"/>
	<path fill="#CC1F36" d="M548.7,78.9c1.4,1.9,2.8,4.7,1.4,7.2c-4.3,3.7-6.2,10-11.7,11.4c-7.1,0.4-11.2-7.4-17.6-6.2
		c-3,3.6-5.3,8.9-10.2,9.5c-8.5-0.2-14.9-5.3-21.7-8.1c-3.2-7.9,3.6-12.1,7.4-18.1c7.1-2.5,9.7,2.3,16.8,5.1c2.8,2.3,5.3,1.5,7,1.1
		c4.9-2.9,7.8-13.4,15.2-10.5L548.7,78.9L548.7,78.9z"/>
	<path fill="#CC1F36" d="M143.4,80.8c-0.1,1.8,1.1,3.7-0.2,5.2c-4,3.4-12.4-3.5-13.1,5c-2.4,7.3,1.3,17.7-6,22.4
		c-6.4-0.3-11.7-4.5-15.2-9.8c-4.6-0.7-10.4-0.1-13.6-4.8c-4.9-4.2-4.6-11.4-2.6-16.7c1.7-2.4,4.2-2.7,6.7-1.7
		c8.1,3.1,14.6,11.2,20.7,16.7c5.6-1.5,1.9-9.2,4.1-13.3c0.4-3.1,2.4-5.9,5-7.6C134.2,73.8,140.5,76.1,143.4,80.8"/>
	<path fill="#CC1F36" d="M667.3,93.4c3.4,7.4,5.9,16.8,3.3,25.5c-1,1.3-1.9,2.7-3.3,2.9c-8.5-2.5-1-13.4-6.4-18.3
		c-4.7-7.3-13.2-12.2-16.7-19.8c-0.5-2.4-0.1-5.1,2.4-6.7c1.8-1.3,4.6-1,6.9,0C658.6,81.3,663.5,87.5,667.3,93.4"/>
	<path fill="#CC1F36" d="M459.8,84.9c1-2.5,4.3-3.3,6.9-2.1c3.4,1.2,4.1,5.3,5.7,8.3c1.4,5.9-2.4,10.7-6.7,14.1c-3.3,1-6.5-0.1-9.3,0.7
		c-3.9,2.4-9,4.7-13.3,2.4c-6.6-3.4-5.9-11.1-6.7-17.4c0.7-2,1.4-4.6,4-5.2c1.6-0.6,2.9,0.7,2.9,2.4c-0.6,2.7-2.5,6-0.2,8.6
		c1.6,1.9,4.4,2.3,6.9,1.4c6.5-3.9-6-18.5,7.1-15.5L459.8,84.9L459.8,84.9z"/>
	<path fill="#CC1F36" d="M33.8,101.1l0.5,3.1c-8.8,23-19.3,45.4-23.4,70.5c-0.6,10.3,2.9,20.2,8.1,28.8c1.7,2.7,5,1.6,7.4,0.9
		c4-0.8,6-7.3,11-5.2c3.4,3.3,0.6,8.1,0.5,12.1c-3.6,6.5-6.2,14.4-13.8,18.4c-2.1,7.6-2.8,16.1-3.1,24.3c-0.1,3.5-0.2,7,1.9,9.8
		c8.2,4.6,17.2-0.4,24.8-3.1c33.1-15.6,65.4-31.5,95.1-55.5c2.2-2.2,5.9-4.7,3.8-8.1c-10.2-12.2-26.1-13.3-38.6-21
		c-13.9-7.3-28.1-13.8-40.7-23.8c-5.9-8.7-16.5-15.8-16.7-26.9c-0.7-4.1,0-8.2,1.2-11.7c1.8-1.4,4.7-1.6,6.9-0.7
		c6.7,5.1,16.2,5.7,21.2,13.1c1.1,3.5-4.2,4.9-1.9,8.3l6.7,5c17.3,10.7,36.4,17.7,54.1,27.6c10.4,8.6,18.2,20.8,17.1,35
		c1.8,10.9-6.2,17.8-12.6,25.5c-31.2,22.5-63,43.5-97.2,58.6c-8.8,3.6-21.2,5.5-29.1-1.4c-6-8.1-7.9-18.3-6.2-28.8
		c1-9.9,2.4-19.4,5.2-28.4C11.5,215.8,2.3,206.1,1.2,193c-2.5-14.1-1-29.2,3.8-41.9c5.1-18.6,14.8-35.1,22.9-52.4
		C29.8,96.5,33.7,98.2,33.8,101.1"/>
	<path fill="#CC1F36" d="M408.1,102c1.6,4.1-0.3,8.5-1.2,12.4l-0.5-0.5c-1.7,1.7-2.5,4.2-5,4.3c-11.4,4.7-24.3,8.4-31,20.7
		c-5.4,12.9-11.3,25.9-9.8,41.4c0.1,8.4,2.1,16.7,9,22.2c5.3,2.3,7.8-3.8,11.2-6.7c1.6-0.4,2.5,1.2,2.9,2.6c0.1,7.8-4,14-6.2,21
		c-2.6,3.3-6.4,5.2-10.2,4.8c-9.4-1.6-14.1-11.8-16-19.5c-3.2-16.1-1-33.5,3.6-48.1l6.9-19.1c0-0.8-0.9-1.2-1.2-1.4l-51.2,25.5
		l-78.9,40.3c-2.9,1.9-7.7,4.4-6.7,8.6c4.3,4.1,11.7,6.6,12.1,13.6c-1.4,1.2-1.7,3.7-3.8,3.8c-7,1.6-10.8-4.3-15.2-8.1
		c-3.8-4.1-3.2-11.5-1.4-16.2c6.6-11.4,18.9-18.3,29.8-24.3l157.5-79.8C405.1,98.8,407.3,100,408.1,102"/>
	<path fill="#CC1F36" d="M498.6,130.6c2.9,6,1.2,13.3,0.5,19.8c-0.9,1.3-3,1.8-4,0.9c-2.8-6.7-1-15-5.7-20.7c-0.8-2.1,0.6-4,2.4-4.5
		C494.6,125.7,497.2,128,498.6,130.6"/>
	<path fill="#CC1F36" d="M621.8,129.6c-2.4,7.6-11.5,17.6-2.4,24.8c2.5-0.1,5.3,0.5,6.7,3.3c0.7,2.6,0.2,5.8-1.4,7.9
		c-2,1.9-4.8,2.7-6.9,1.7c-8-5.1-10-14.8-8.3-23.8c0.2-5.1,0.4-10.3,3.6-14.3C615.1,127.2,620,126.5,621.8,129.6"/>
	<path fill="#CC1F36" d="M448.4,151.8c1.4,2.4,2.8,5.6,1.7,8.8c-4.8,9.6-19.6,1.5-22.9,12.1c2.4,20.2,16,39.5,7.6,61
		c-2.4,1-3.6-1.6-3.6-3.3c-1.5-14.1-4.4-27.7-9.5-40c-2.7-9.1-5-21.4,1.7-29.3c-1.4-3.6-2.6-8.7-0.2-12.1c2.4-3.3,6.1-4.8,10.2-5
		C439,144.7,443.4,148.5,448.4,151.8"/>
	<path fill="#CC1F36" d="M77.9,208.8c-3.4-0.4-7.6,0.1-9.1-3.6c-0.2-3.1-0.1-6.4,2.6-7.9c3.2,0.9,7.2,1.6,8.8,5.5
		C80.6,204.8,80.1,207.5,77.9,208.8 M86.5,197.3c-1.8-3-4.5-5.1-7.9-5.5c-3,2.8-4.5-1-4.8-3.3c-1.9-1-3.5,0.4-4.8,1.7
		c-3.7,6.6-8.5,15.2-3.8,23.1c1,3.5,4.8,5.3,8.1,6.2c3.9,0.2,7.7-0.1,10.2-3.3C87.8,211.1,89.7,203.2,86.5,197.3"/>
	<path fill="#CC1F36" d="M311.6,202.3c0.2,1.8,1.3,4.3,0,6.2c-3.4,4.7-11.5-0.9-13.3,5.7c-3.3,6.5,0.8,16.9-6.2,21.2
		c-4.3-0.5-9-1.5-10.5-6c-0.5-2.2-2-4.6-4-5.2c-3.9-1.2-9.5,0.6-11-4.3c-1-3.9-0.8-8.6,1.7-11.7c2.1-1.9,5.6-1.7,8.1-0.2
		c5.5,3.9,7.5,11.5,13.3,16c4.5-6.9,2.2-17,8.3-23.4c1-1.7,2.6-2.1,4.3-2.6C306,197.6,309.6,198.9,311.6,202.3"/>
	<path fill="#CC1F36" d="M732.1,231.9c-0.7,5.4-4.4,11-1.4,16.4c1.9,1.7,4.8,2.6,6,5.7c-0.8,2.7-3.1,5.5-5.5,6.9
		c-2.4,1.3-5.5,0.4-7.4-0.2c-8.6-9.4-2.3-22.7,2.9-31.4C728.6,229.2,732,228.7,732.1,231.9"/>
	<path fill="#CC1F36" d="M604.9,239.2c0.9,4.7-4.8,6.3-7.2,8.8c-16.8,6.1-32.4,14.7-49.1,20.2c-3.6,2.5-9.2,5.8-13.8,3.8
		c-1.5-1.2-0.8-3.4-0.5-4.8c5-5.8,11.9-9.2,18.6-12.6l37.9-18.4C594.9,234.6,602.3,232.8,604.9,239.2"/>
	<path fill="#CC1F36" d="M179.4,255.4c0,2.2-1.9,4.3-3.8,5.2c-6.6,1,0.3,9.8-7.4,10.5c-4.9,0.9-7.9,4.8-9.5,9.3
		c-4.9,4.7-11.8,0.3-17.1,3.8c-5.7,0.4-0.9-7-5-7.9c-4.9,0.5-9.8,2.5-13.6-1.7c-1.9-1.6-2.4-4.4-1.4-6.4c3.9-3.6,1-10.1,4.3-13.6
		c2.9,2.9,0.2,8.5,1.4,12.4c3.8,7.2,9.9,0.2,15-0.2c2-2.2,3.9-3.8,5-6.2c3.2-1.6,6.1-5.5,10.2-3.6c4.2,4.2-2.5,8.1-3.8,12.1
		c0.2,1.2,1.7,2.7,3.1,2.9c3.6-0.9,3.1-5.1,3.8-7.9c3.3-5.5,9.3-6,13.1-10.5C175.8,253.5,178.1,253.7,179.4,255.4"/>
	<path fill="#CC1F36" d="M511.3,278.1c-0.8,1.9-3.5,1.1-4.5,0.7c-6.6-5-12.7-11.4-13.8-19.1c-0.4-2.7,3.1-2.3,4.5-1.2
		C504.3,263.1,508.7,270.5,511.3,278.1"/>
	<path fill="#CC1F36" d="M120.3,265.5c-0.5,5.2-8.3,4.7-8.8,10.5c-1.8,5.1-1.7,11.6-1.9,16.9c-1.1,1.1-2.2-0.3-3.1-0.5
		c-3.2-8-0.7-17.1,3.1-23.8l-0.5-1.2c0.7-0.5,0.4,0.5,0.9,0.7C112.5,265.3,118.3,258.7,120.3,265.5"/>
	<path fill="#CC1F36" d="M129.1,282.6c0.2,2.2-2,3-3.6,3.8c-2,0.3-4.3,0.3-5.7-1c-0.8-2.9,2-4.5,4.1-5
		C125.7,280.1,128.6,280.2,129.1,282.6"/>
</g>
</svg>
  );
};

export default LogoSvg;


