import { ReactNode } from "react";
import SingleRelatedPosts from "../SingleRelatedPosts";
import { Helmet } from "react-helmet";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div>

      {children}

     

      {/* RELATED POSTS */}
      <SingleRelatedPosts />
    </div>
  );
};

export default Layout;
