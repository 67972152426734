import NcImage from "components/NcImage/NcImage";
import Layout from "../layout";
import SingleTitle from "app/(singles)/SingleTitle";
import axios from 'axios'
import {useState, useEffect ,useRef }  from "react";
import { useParams } from "react-router";
import { AlittihadFeatures } from "data/types";
import { FC } from "react";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import Badge from "components/Badge/Badge";
import Link from "components/Link";
import Avatar from "components/Avatar/Avatar";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import { SOCIALS_DATA } from "components/SocialsShare/SocialsShare";
import NcBookmark from "components/NcBookmark/NcBookmark";
import NcDropDown from "components/NcDropDown/SocialDown";
import { useLocation } from "react-router-dom";
import { useSpeech } from "react-text-to-speech";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import MetaTags from "data/metatags";
import { Helmet } from "react-helmet";

export interface SingleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  size?: "large" | "normal";
  avatarRounded?: string;
  hiddenCategories?: boolean;

}
export interface SocialType {
  id: string;
  name: string;
  icon: string;
  slug: string;
}

export interface SingleContentProps {


}


const PageSingle = () => {


  const location = useLocation();
  const params = useParams();
  const [posts, setPost] = useState<AlittihadFeatures[]>([]);
  const [fontSize, setFontSize] = useState(16);
  const [showTools, setShowTools] = useState(true);



  const url = "https://alittihad44.com/";
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await axios.get(
      "https://alittihad44.com/api/v3/new/" + params.curl + "/"+params.slug
        );
        setPost(response.data);


  };

  let tParser = document.createElement('div');
  tParser.innerHTML = posts.map(item => item.article).toString() ;


  const {
    Text, // Component that returns the modified text property
    speechStatus, // String that stores current speech status
    isInQueue, // Boolean that stores whether a speech utterance is either being spoken or present in queue
    start, // Function to start the speech or put it in queue
    pause, // Function to pause the speech
    stop, // Function to stop the speech or remove it from queue
    
  } = useSpeech({ text: tParser.textContent, lang: 'ar'});




  const SingleHeader: FC<SingleHeaderProps> = ({
    titleMainClass,
    className = "",
    size = "normal",
    avatarRounded,
  }) => {

    const updated_at = posts.map(item => item.updated_at)

    let date = new Date(updated_at.toString());
    var months = ["كانون ثاني/يناير", "شباط/فبراير", "آذار/مارس", "نيسان/إبريل", "أيار/مايو", "حزيران/يونيو",
      "تموز/يوليو", "آب/أغسطس", "أيلول/سبتمبر", "تشرين أول/أكتوبر", "تشرين ثاني/نوفمبر", "كانون أول/ديسمبر"
    ];
    var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
    var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();

    
    return (
      <>
       {posts.map((item, index) => (
        
<div key={index} className={`nc-SingleHeader ${className}`}>  
  <div className="space-y-5">
    <div className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}
           data-nc-id="CategoryBadgeList">
        <Badge
          key={index}
          name={item.ctitle}
          href={item.curl}
        />
    </div>
       <SingleTitle
       mainClass={titleMainClass}
       stitle={item.stitle}
       />
      <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
       {/*  meta data  */}

      <div className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${size === "normal" ? "text-xs" : "text-sm"
      } leading-none`}>

      <Link href={'/writer/'+item.href} className="flex items-center space-x-2">
        <Avatar
          radius={avatarRounded}
          sizeClass={
            size === "normal"
              ? "h-6 w-6 text-sm"
              : "h-10 w-10 sm:h-11 sm:w-11 text-xl"
          }
          imgUrl={'https://alittihad44.com/images/users/'+item.uimage
}
          userName={item.name}
        />
      </Link>
      <div className="ml-3">
        <div className="flex items-center">
          <Link href={'/writer/'+item.href} className="block font-semibold">
            {item.name}
          </Link>

     
        </div>
        <div className="text-xs mt-[6px]">
          <span className="text-neutral-700 dark:text-neutral-300">{delDateString}</span>    
        </div>
      </div>
    </div>
               {/*end  meta data  */}


              {/* share links  */}

     <div className={`nc-SingleMetaAction2`}>
      <div className="flex flex-row space-x-2.5 items-center">
      <NcBookmark containerClassName="h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200" />
        <PostCardLikeAndComment
          itemClass="px-4 h-9 text-sm"
          hiddenCommentOnMobile
          useOnSinglePage
          className="!space-x-2.5"
          
        />
        <div className="px-1">
          <div className="border-l border-neutral-200 dark:border-neutral-700 h-6" />
        </div>
        <NcDropDown
          className="flex-shrink-0 flex items-center justify-center focus:outline-none h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
          renderTrigger={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
          )}
          onClick={(item: SocialType) =>
         (
          window.open(item.slug + 'https://alittihad44.com'+ location.pathname), '_blank', 'noopener'     
          )}
          data={SOCIALS_DATA}
        />
     
      </div>
    </div>

              {/*end share links  */}
            </div>
          </div>
        </div>
            ))}
      </>
    );
  };

  const SingleContent: FC<SingleContentProps>  = () => {
    const endedAnchorRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const progressRef = useRef<HTMLButtonElement>(null);
    //
    const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
    //

    const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
      threshold: 0,
      root: null,
      rootMargin: "0%",
      freezeOnceVisible: false,
    });
    useEffect(() => {
      const handleProgressIndicator = () => {
        const entryContent = contentRef.current;
        const progressBarContent = progressRef.current;
  
        if (!entryContent || !progressBarContent) {
          return;
        }
  
        const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
        let winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        let scrolled = (winScroll / totalEntryH) * 100;
  
        progressBarContent.innerText = scrolled.toFixed(0) + "%";
  
        if (scrolled >= 100) {
          setIsShowScrollToTop(true);
        } else {
          setIsShowScrollToTop(false);
        }
      };
  
      const handleProgressIndicatorHeadeEvent = () => {
        window?.requestAnimationFrame(handleProgressIndicator);
      };
      handleProgressIndicator();
      window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
      return () => {
        window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
      };
    }, []);
  
    const showLikeAndCommentSticky =
      !endedAnchorEntry?.intersectionRatio &&
      (endedAnchorEntry?.boundingClientRect.top || 0) > 0;
  
    return (
      <div className="relative">
       {posts.map((item, index) => ( 
        <div key={index} className="nc-SingleContent space-y-10">

    
    <div className="trinity-tts-pb" dir="ltr" style={{font: '12px / 18px ', height: '80px', lineHeight: '80px', textAlign: 'left', margin: '0 0 0 82px'}}>
      <strong style={{fontWeight: '400'}}>Getting your <a href="//trinityaudio.ai" style={{color: '#4b4a4a', textDecoration: 'none', fontWeight: '700'}}>Trinity Audio</a> player ready...</strong>
</div>
 
          {/* ENTRY CONTENT */}
      
          <div style={{ fontSize: `${fontSize}px`}} onClick={() => setShowTools(!showTools)} dangerouslySetInnerHTML={{__html: item.article}}
            id="single-entry-content"
            className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert balo"
            ref={contentRef}
            
          >
          </div>


          {/* TAGS */}
          <div className="max-w-screen-md mx-auto flex flex-wrap">
            {posts.map((item) => (
              <Link
              className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900`}
              href={'/tags/'+item.tslug}
            >
              {`${item.tag}`}
             
            </Link>
            ))}
          </div>
          {/* AUTHOR */}
          <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
          <div className="max-w-screen-md mx-auto ">
          <div className="nc-SingleAuthor flex">
      <Link href={item.href}>
        <Avatar
          imgUrl={'https://alittihad44.com/images/users/'+item.uimage}
          userName={item.name}
          sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24"
        />
      </Link>
      <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
        <span className="text-xs text-neutral-400 uppercase tracking-wider">
          الكاتب/ة
        </span>
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          <Link href={'/writer/'+item.href}>{item.name}</Link>
        </h2>
        <span className="block mt-1 text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
          {item.about_writer}
          <Link
            className="text-primary-6000 font-medium ml-1"
            href={'/writer/'+item.href}
          >
            ...إلى صفحة الكاتب
          </Link>
        </span>
      </div>
    </div>
          </div>
                  {/* COMMENTS LIST */}
        <div className="max-w-screen-md mx-auto">
          <div ref={endedAnchorRef}></div>
        </div>
        </div>

      ))}
            <div
        className={`sticky mt-8 bottom-8 z-40 justify-center ${
          showLikeAndCommentSticky ? "flex" : "hidden"
        }`}
      >
        <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
          <PostCardLikeAction className="px-3 h-9 text-xs" />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
       
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

          <button
            className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
              isShowScrollToTop ? "flex" : "hidden"
            }`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="w-4 h-4" />
          </button>

          <button
            ref={progressRef}
            className={`w-9 h-9 items-center justify-center ${
              isShowScrollToTop ? "hidden" : "flex"
            }`}
            title="Go to top"
          >
            %
          </button>
        </div>
      </div>
      </div>
    
    );
  };


  return (
    <Layout>
      {posts.map((item, index) => (
      <div key={index} className={`nc-PageSingle pt-8 lg:pt-16`}>
     <MetaTags 
     title={item.stitle}
     description={item.article}
     image={url + item.slugimage}
     
     />
     


        
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader/>
          </div>
        </header>

        {/* FEATURED IMAGE */}
        <NcImage
          alt="single"
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src={url + item.slugimage}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />

       {/* SINGLE MAIN CONTENT */}
       <div className="container mt-10">
       <SingleContent/>
              
      </div>

      </div>

            ))}
    </Layout>
    
  );


  
};

export default PageSingle;
