import React, { FC, ReactNode } from "react";
import Heading from "components/Heading/Heading";
import Card10V2 from "components/Card10/Card10V2";
import { AlittihadFeatures } from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import GetHomePage from "data/home";

// OTHER DEMO WILL PASS PROPS

//
export interface SectionGridPostsProps {
  posts?: AlittihadFeatures[];
  className?: string;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  postCardName?:

    | "card10V2"

}

const SectionGridPosts: FC<SectionGridPostsProps> = ({
  posts ,
  postCardName = "card10V2",
  className = "",
  gridClass = "",
  heading,
  subHeading,
  headingIsCenter,
}) => {
  const renderCard = (post: AlittihadFeatures) => {
    switch (postCardName) {

      case "card10V2":
        return <Card10V2 key={post.id} post={post} />;
   
    }
  };

  return (
       <div className={`nc-SectionGridPosts relative ${className}`}>
      <Heading desc={subHeading} isCenter={headingIsCenter}>
        {heading}
      </Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
        {GetHomePage().videos.map((post) => renderCard(post))}
      </div>
      <div className="flex mt-20 justify-center items-center">
      </div>
    </div>
  );
};

export default SectionGridPosts;
