import NcImage from "components/NcImage/NcImage";
import { AlittihadFeatures } from "data/types";
import { FC } from "react";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Badge from "components/Badge/Badge";
import Avatar from "components/Avatar/Avatar";
import { useState , useEffect } from "react";


export interface CardLarge1Props {
  className?: string;
  post: AlittihadFeatures;
  onClickNext?: () => void;
  onClickPrev?: () => void;
}


const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  post,
  onClickNext = () => {},
  onClickPrev = () => {},
}) => {
  const {id, slugimage, stitle, updated_at, categorie_id, slug, ctitle, curl, article, post_type, href, name, uimage } = post;
  let date = new Date(updated_at);
  var months = ["كانون ثاني/يناير", "شباط/فبراير", "آذار/مارس", "نيسان/إبريل", "أيار/مايو", "حزيران/يونيو",
    "تموز/يوليو", "آب/أغسطس", "أيلول/سبتمبر", "تشرين أول/أكتوبر", "تشرين ثاني/نوفمبر", "كانون أول/ديسمبر"
  ];
  var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
  var delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
  
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  return (
    

<div>

    <div className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse md:flex-row justify-start ${className}`}>
  
      <div className="md:absolute z-10 md:left-0 md:top-1/2 md:-translate-y-1/2 w-full -mt-8 md:mt-0 px-3 sm:px-6 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5">
        <div className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white/40 dark:bg-neutral-900/40 backdrop-filter backdrop-blur-lg shadow-lg dark:shadow-2xl rounded-3xl space-y-3 sm:space-y-5 ">
        <div className={`nc-CategoryBadgeList flex flex-wrap space-x-2"`}
      data-nc-id="CategoryBadgeList">
        <Badge
          key={id}
          name={ctitle}
          href={curl}
          color="red"
        />
    </div>

          <h2 className="nc-card-title text-base sm:text-xl lg:text-2xl font-semibold ">
            <Link key={curl} href={curl +"/"+ slug } className="line-clamp-2" title={stitle}>
              {stitle}
            </Link>
          </h2>
          {/*  test */ }
    <Link
      href={"/writer/"+ href}
      className={`nc-CardAuthor2 relative inline-flex items-center ${className}`}
    >
      <Avatar
        sizeClass="h-10 w-10 text-base"
        containerClassName="flex-shrink-0 mr-3"
        radius="rounded-full"
        imgUrl={'https://alittihad44.com/images/users/'+uimage}
        userName={name}
      />
      <div>
        <h2
          className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
        >
          {name}
        </h2>
        <span
          className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}
        >
          <span>{delDateString}</span>
         
        </span>
      </div>
    </Link>
    {/* end test  */ }
          <div className="flex items-center justify-between mt-auto">
          </div>
        </div>
      </div>
      <div className="w-full md:w-4/5 lg:w-2/3">
      
        <Link key={curl} href={curl +"/"+ slug } className="nc-CardLarge1__right block relative">
          
          <NcImage
          
            containerClassName="aspect-w-16 aspect-h-12 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-10 2xl:aspect-h-9 relative"
            className="absolute inset-0 object-cover rounded-3xl"
            loading = "lazy"
            alt={stitle}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
            src={'https://alittihad44.com/' + slugimage}
          />
          {/* META TYPE */}
          <PostTypeFeaturedIcon
          postType={post_type}
            className="absolute w-8 h-8 md:w-10 md:h-10 right-6 top-6"
          />
        </Link>
      </div>
      
    </div>


</div>
  );
};

export default CardLarge1;
