import Card16Podcast from "components/Card16Podcast/Card16Podcast";
import Card17Podcast from "components/Card17Podcast/Card17Podcast";
import Heading from "components/Heading/Heading";
import { AlittihadFeatures } from "data/types";
import React, { FC } from "react";
import GetHomePage from "data/home";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import Link from "components/Link";
import Image from "components/Image";
import ButtonPlayMusicPlayer from "../ButtonPlayMusicPlayer";
import musicWave from "images/musicWave.png";
import Badge from "components/Badge/Badge";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";


export interface SectionMagazine8Props {
  posts?: AlittihadFeatures[];
  className?: string;
}

const renderListenButtonDefault = (state?: "playing") => {
  return (
    <div
      className={`w-14 h-14 flex items-center justify-center rounded-full bg-neutral-50 text-primary-500 cursor-pointer`}
    >
      {state === "playing" ? (
        <PauseIcon className="w-8 h-8" />
      ) : (
        <PlayIcon className="ml-0.5 w-8 h-8" />
      )}
    </div>
  );
};

const SectionMagazine8: FC<SectionMagazine8Props> = ({
  className = "",
}) => {

  const posts = GetHomePage().audio

  return (
    <div className={`nc-SectionMagazine8 relative ${className}`}>
      {!GetHomePage().audio.length &&
      <Heading
        desc={"اضغطوا واستمعوا واستمتعوا"}
        className="mb-14 text-neutral-900 dark:text-neutral-50"
      >
        بودكاست الاتحاد
      </Heading>
}
      <div className={`grid grid-cols-1 sm:grid-cols-6 gap-6 md:gap-8`}>
      
        {/*  start card 16   */}
      {posts.filter((_, i) => i < 2).map((item, index) => (
      <div className={`nc-Card16Podcast relative flex flex-col sm:col-span-3 lg:col-span-2`}>
      <Link
        href={item.curl +"/"+ item.slug }
        className={`block flex-shrink-0 relative w-full rounded-3xl overflow-hidden aspect-w-3 xl:aspect-w-4 aspect-h-3`}
      >
        <Image
          fill
          alt=""
          sizes="(max-width: 600px) 480px, 800px"
          src={'https://alittihad44.com/' + item.slugimage}
          className="object-cover"
        />
        <span className="bg-neutral-900 bg-opacity-30"></span>
      </Link>

      {/* ABSOLUTE */}
      <Link href={item.curl +"/"+ item.slug} className="absolute inset-0"></Link>
      <span className="absolute top-3 inset-x-3">
      <div
      className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}
      data-nc-id="CategoryBadgeList"
    >
     
        <Badge
          className={'flex flex-wrap space-x-2"'}
          key={index}
          name={item.ctitle}
          href={item.curl}
          color={"red"}
        />
     
    </div>

      </span>

      {/* MAIN CONTENT */}
      <div className="w-11/12 transform -mt-32 ">
        <div
          className={`px-5 flex items-center space-x-4 ${
            !item.post_type ? "relative opacity-0 z-[-1]" : ""
          }`}
        >
          <div className={`flex-grow `}>
            <Image src={musicWave} alt="musicWave" />
          </div>
          <ButtonPlayMusicPlayer
            post={item}
            renderDefaultBtn={() => renderListenButtonDefault()}
            renderPlayingBtn={() => renderListenButtonDefault("playing")}
          />
        </div>
        <div className="p-5 mt-5 bg-white dark:bg-neutral-900 shadow-xl dark:shadow-2xl rounded-3xl rounded-tl-none flex flex-col flex-grow ">
          <h2 className="nc-card-title block sm:text-lg lg:text-xl font-semibold text-neutral-900 dark:text-neutral-100 ">
            <Link href={item.curl +"/"+ item.slug}  title={item.stitle}>
              {item.stitle}
            </Link>
          </h2>
          <div className="flex items-end justify-between mt-auto">
          </div>
        </div>
      </div>
    </div>
        ))}
    {/*  end card 16  */ }
    
        <div className="flex flex-col space-y-6 md:space-y-8 sm:col-span-6 lg:col-span-2">
          {posts
            .filter((_, i) => i > 1 && i < 6)
            .map((p) => (
              <Card17Podcast key={p.id} post={p} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine8;
