import React, { FC } from "react";
import { AlittihadFeatures } from "data/types";
import GallerySlider from "./GallerySlider";
import MediaVideo from "./MediaVideo";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import MediaAudio from "./MediaAudio";
import Link from "components/Link";
import Image from "components/Image";

export interface PostFeaturedMediaProps {
  className?: string;
  post: AlittihadFeatures;
  isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
  className = "w-full h-full",
  post,
  isHover = false,
}) => {
  const {id, slugimage, stitle, updated_at, categorie_id, name, slug, ctitle, curl, article, post_type, mediaurl } = post;


  const isPostMedia = () => post_type === "video" || post_type === "audio";



  const renderContent = () => {
    // GALLERY
  

    // VIDEO
    if (post_type === "video" && !!mediaurl && isHover) {
      return <MediaVideo isHover videoUrl={mediaurl} />;
    }

    // AUDIO
    if (post_type === "audio" && !!mediaurl) {
      return <MediaAudio post={post} />;
    }

    // ICON
    return isPostMedia() ? (
      <span className="absolute inset-0 flex items-center justify-center ">
        <PostTypeFeaturedIcon
          className="hover:scale-105 transform cursor-pointer transition-transform"
          postType={post_type}
        />
      </span>
    ) : null;
  };

  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
      {post_type !== "gallery" && (
        <Image
          alt="featured"
          fill
          className="object-cover"
          src={"https://alittihad44.com/" + slugimage}
          sizes="(max-width: 600px) 480px, 800px"
        />
      )}
      {renderContent()}
      {post_type !== "gallery" && (
        <Link
          href={curl + '/' + slug}
          className={`block absolute inset-0 ${
            !post_type || post_type === "standard"
              ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
              : ""
          }`}
        />
      )}
    </div>
  );
};

export default PostFeaturedMedia;


