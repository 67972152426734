import { useState, useEffect } from "react";
import { AlittihadFeatures } from "data/types";
import { AlittihadCategories } from "data/types";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import { DEMO_AUTHORS } from "data/authors";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import Input from "components/Input/Input";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImage";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Card11 from "components/Card11/Card11";
import ButtonCircle from "components/Button/ButtonCircle";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import axios from 'axios'
import ReactPaginate from "react-paginate";
import twFocusClass from "utils/twFocusClass";


const TABS = ["الأخبار", "المقالات", "كتّاب الموقع"];

const PageSearch = () => {
  let s = "Technology";

  const [tabActive, setTabActive] = useState(TABS[0]);
  const [posts, setPost] = useState<AlittihadFeatures[]>([]);
  const [mulhaqs, setMulhaqs] = useState<AlittihadFeatures[]>([]);
  const [resWriter, setResWriters] = useState<AlittihadFeatures[]>([]);
  const [search, setSearch] = useState("ا");
  const [totalPages, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [isHover, setIsHover] = useState(false);



  useEffect(() => {
   
    fetchData();
  }, [search, page]);


    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v3/search/news/"+search+"?page="+ page
      );
      const resMulhaq = await axios.get(
        "https://alittihad44.com/api/v3/search/mulhaq/"+search+"?page="+ page
      );

      const resWriters = await axios.get(
        "https://alittihad44.com/api/v3/search/writers/"+search+"?page="+ page
      );
      setPost(response.data.data);
      setMulhaqs(resMulhaq.data.data);
      setResWriters(resWriters.data.data);

    };

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  const changePage = ({ selected }:any) => {
    setPage(selected);
};

  return (




    <div className={`nc-PageSearch`} >
      {/* HEADER */}
      <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto" >
        <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-9 lg:aspect-h-5 overflow-hidden z-0">
          <NcImage
            alt="بحث"
            fill
            containerClassName="absolute inset-0"
            src="https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="object-cover w-full h-full"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
        </div>
        {/* CONTENT */}
        <div className="relative container -mt-20 lg:-mt-48">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
            <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
              <h2 className="text-2xl sm:text-4xl font-semibold">شريط البحث</h2>
         
              <div
                className="relative w-full mt-8 sm:mt-11 text-left"
              >
                <label
                  htmlFor="search-input"
                  className="text-neutral-500 dark:text-neutral-300"
                >
                  <span className="sr-only">بحث</span>
                  <Input
                    id="search-input"
                    type="search"
                    placeholder="بحث في الموقع"
                    sizeClass="pl-14 py-5 pr-5 md:pl-16"
                    onChange={(event) => setSearch(event.target.value)}

                  />
                  <ButtonCircle
                    className="absolute left-2.5  top-1/2 transform -translate-y-1/2"
                    size=" w-11 h-11"
                    type="submit"
                  >
                    <ArrowSmallRightIcon className="w-6 h-6" />
                  </ButtonCircle>
                  <span className="absolute right-2.5  top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
              <div className="w-full text-sm text-left mt-4 text-neutral-500 dark:text-neutral-300">
             
              </div>
            </header>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* TABS FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className="sm:space-x-2"
            >
              {TABS.map((item, index) => (
                <NavItem
                  isActive={item === tabActive}
                  key={index}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          </div>

          
            {/* LOOP ITEMS POSTS */}
            
            {tabActive === "الأخبار" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              { posts.length && posts.map((post) => (
                <Card11 key={post.id} post={post} />
              ))}
            </div>
          )}

          {/* LOOP ITEMS */}
          {/* LOOP ITEMS CATEGORIES */}
          {tabActive === "المقالات" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              { mulhaqs.length && posts.map((post) => (
                <Card11 key={post.id} post={post} />
              ))}
            </div>
          )}
       
           
          {/* LOOP ITEMS POSTS   */}
          {tabActive === "كتّاب الموقع" && (
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 md:gap-8 mt-8 lg:mt-10">
              {resWriter.map((author) => (
                <CardAuthorBox2 key={author.id} author={author} />
              ))}
            </div>
          )}
          

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
          <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
    >
          <ReactPaginate
          
            pageRangeDisplayed={3} //The range of buttons pages displayed.
            previousLabel={"السابق"} //lable for previous page button
            nextLabel={"التالي"} // lable for Next page button
            pageCount={totalPages} // place here the variable for total number of pages
            onPageChange={changePage} // trigger event function
            /// navigation styling ///
            containerClassName={`nc-Pagination inline-flex space-x-1 text-base font-medium `}
            previousLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            nextLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            disabledClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            activeClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white focus:outline-none bg-primary-6000 ${twFocusClass()}`}
            activeLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-red  focus:outline-none ${twFocusClass()}`}
            pageLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full hover:bg-neutral-100 border border-neutral-200 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}

            /// end navigation styling ///
        />
        </nav>
          </div>
        </main>

        {/* MORE SECTIONS */}

      </div>
    </div>
  );
};

export default PageSearch;
