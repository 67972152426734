
import axios from 'axios'
import { Tags } from "./types";

import { useState, useEffect } from "react";

export default function GetTags() {

  const [tags, setTags] = useState<Tags[]>([]);


  useEffect(() => {
   
    fetchData();
  }, []);


    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v3/tags/latest"
      );
      setTags(response.data);

   };

return {tags} ;
     

}

