import ModalCategories from "../ModalCategories";
import ModalTags from "../ModalTags";
import { AlittihadCategories } from "data/types";
import { AlittihadFeatures } from "data/types";
import GetTags from "data/tags";
import Image from "components/Image";
import axios from 'axios'
import  {useState, useEffect } from "react";
import { useParams } from "react-router";
import Link from "components/Link";
import twFocusClass from "utils/twFocusClass";
import ReactPaginate from "react-paginate";


const PageArchive = () => {
  
  const params = useParams();
  const [NewsCategories, setCat] = useState<AlittihadCategories[]>([]);
  const [News, setData] = useState<AlittihadFeatures[]>([]);
  const [totalPages, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [isHover, setIsHover] = useState(false);



  useEffect(() => {
   
    fetchData();
  }, [page]);
    const fetchData = async () => {
     
      const News = await axios.get(
        "https://alittihad44.com/api/v3/category/" + params.curl+"?page="+ page
      );
      const cats = await axios.get(
        "https://alittihad44.com/api/v3/categories/");

      setData(News.data.data)
      setCat(cats.data)
      setTotal(News.data.to)
    };

    const changePage = ({ selected }:any) => {
        setPage(selected);
    };

  
  return (
    <div className={`nc-PageArchive`}>
  

      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:justify-between sm:flex-row">
            <div className="flex space-x-2.5">
           <ModalCategories categories={NewsCategories} />
              <ModalTags tags={GetTags().tags} />
            </div>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            <div className="flex justify-end">
            </div>
          </div>
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
          {News.map(({ id, stitle, slug, slugimage, curl }) => (
                      <div
                      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 h-full`}
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                      //
                    >
                      <div
                        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 aspect-w-4 aspect-h-3`}
                      >
                        <div>
                           <div className={`nc-PostFeaturedMedia relative h-full`}>
                   
                        <Image
                          alt="featured"
                          fill
                          className="object-cover"
                          src={"https://alittihad44.com/" +slugimage}
                          sizes="(max-width: 600px) 480px, 800px"
                        />
                   
                
                        <Link
                          href={"/"+curl+"/"+slug}
                          className={`block absolute inset-0 bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
                           
                          }`}
                        />
                   
                    </div> 
                        </div>
                      </div>
                      <Link href={"/"+curl+"/"+slug} className="absolute inset-0"></Link>
                      <span className="absolute top-3 inset-x-3 z-10">
                       {/*} <CategoryBadgeList categories={categorie_id} /> */}
                      </span>
                
                      <div className="p-4 flex flex-col space-y-3">
                      {/*
                       {!hiddenAuthor ? (
                          <PostCardMeta meta={post} />
                        ) : (
                          <span className="text-xs text-neutral-500">{updated_at}</span>
                        )}
                
                       */}
                        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
                          <span className="line-clamp-2" title={stitle}>
                            {stitle}
                          </span>
                        </h3>
                       
                      </div>
                    </div>
            ))}
          </div>

          
          </div>

          {/* PAGINATIONS */}
     
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
          <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
    >
          <ReactPaginate
          
            pageRangeDisplayed={3} //The range of buttons pages displayed.
            previousLabel={"السابق"} //lable for previous page button
            nextLabel={"التالي"} // lable for Next page button
            pageCount={totalPages} // place here the variable for total number of pages
            onPageChange={changePage} // trigger event function
            /// navigation styling ///
            containerClassName={`nc-Pagination inline-flex space-x-1 text-base font-medium `}
            previousLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            nextLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            disabledClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
            activeClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white focus:outline-none bg-primary-6000 ${twFocusClass()}`}
            activeLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-red  focus:outline-none ${twFocusClass()}`}
            pageLinkClassName={`inline-flex w-11 h-11 items-center justify-center rounded-full hover:bg-neutral-100 border border-neutral-200 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}

            /// end navigation styling ///
        />
        </nav>
          
          </div>
           {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}

        </div>
      </div>

  );

};

export default PageArchive;
