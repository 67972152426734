import React, { FC } from "react";
import Link from "components/Link";

export interface Card3SmallProps {
  className?: string;
  post: any;
}

const Cardbreaking: FC<Card3SmallProps> = ({ className = "h-full", post }) => {
  const { id, btitle, barticle} = post;

  return (
    <div
      className={`nc-Card3Small relative flex flex-row justify-between items-center ${className}`}
    >
      <Link href={'/breaking'} className="absolute inset-0" title={btitle}></Link>
      <div className="relative space-y-2">
       {/*  <PostCardMeta meta={{ ...post }} /> */}
        <h2 className="nc-card-title block text-sm sm:text-base font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100">
          <Link href={'/breaking'} className="line-clamp-2" title={btitle}>
            {btitle}
          </Link>
        </h2>
      </div>

    </div>
  );
};

export default Cardbreaking;
